<template>
  <table class="table-auto w-full bg-surface-card-active rounded-sm shadow">
    <thead>
      <tr class="text-sm">
        <th class="px-4 py-2 text-left text-secondary" v-for="column in columns" :key="column.name">
          {{ column.text }}
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-if="isLoading">
        <tr class="border-t" v-for="(placeholder, index) in placeholders" :key="index">
          <td class="px-4 py-2" v-for="column in columns" :key="column.name">
            <div class="loading"></div>
          </td>
          <td class="px-4 py-2 w-1">
            <div class="loading"></div>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr
          v-if="!items.length"
          class="border-t resource-row cursor-pointer hover:bg-secondary-100"
        >
          <td :colspan="columns.length + 1" class="px-4 py-2">There are no items to show.</td>
        </tr>

        <tr
          :key="item.id"
          v-for="item in items"
          @click="$emit('view', item)"
          class="border-t resource-row cursor-pointer hover:bg-secondary-100"
        >
          <td class="px-4 py-2" v-for="column in columns" :key="column.name">
            <component
              v-if="column.component"
              :item="item"
              :column="column"
              :is="column.component"
              :value="column.render(item)"
            />
            <div v-else>{{ column.render(item) }}</div>
          </td>
          <td class="px-4 py-2">
            <slot v-bind:item="item"></slot>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import DateTime from './Columns/DateTime';

export default {
  components: {
    DateTime,
  },
  computed: {
    placeholders() {
      return new Array(15).fill(false);
    },
  },
  props: {
    columns: Array,
    isLoading: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loading {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    var(--color-gray-800) 10%,
    var(--color-surface-card) 18%,
    var(--color-gray-800) 33%
  );
  background-size: 800px 104px;
  height: 25px;
  position: relative;
}

.action-dropdown {
  opacity: 0;
}

.resource-row:hover .action-dropdown {
  opacity: 1;
}
</style>
