<template>
  <div class="flex font-medium items-center px-3 text-secondary text-sm">
    <div class="py-1">Current Page: {{ meta.current_page }}</div>
    <div class="border-l border-r mx-3 px-3 py-1">Per Page: {{ meta.per_page }}</div>
    <div v-if="!meta.infinite" class="py-1">Total: {{ meta.total }}</div>
  </div>
</template>

<script>
export default {
  props: {
    meta: Object,
  },
};
</script>
