<template>
  <div class="flex flex-col flex-1">
<!--    <Search @change="onSearch"  @input="onSearch" v-if="searchable && !dateFilter" />-->
    <Search  @input="onSearch" v-if="searchable && !dateFilter" />
    <Alert type="danger" :message="error" v-if="error" />
    <NoItems :name="name" :route="createRoute" v-if="isEmpty && !forceTable" />
    <template v-else-if="!error">
      <ResourceTable :items="items" :columns="columns" :is-loading="isLoading" @view="onView">
        <template v-slot:default="slotProps" v-if="actions.length && !disableActions">
          <slot :item="slotProps.item"></slot>
        </template>
      </ResourceTable>
      <div
          class="mt-3 bg-surface-card-active flex justify-between rounded-sm"
          v-if="meta && (meta.total || meta.infinite)">
        <PageInfo :meta="meta" />
        <Pagination :meta="meta" @change="onPaginate" />
      </div>
    </template>
  </div>
</template>

<script>
import Alert from '@/components/Alert';
import actionItems from './action-items';
import Search from './Search';
import NoItems from './NoItems';
import PageInfo from './PageInfo';
import ResourceTable from './ResourceTable';
import Pagination from './Pagination';
import {DataStore} from '@aws-amplify/datastore';
import API, {graphqlOperation} from "@aws-amplify/api";
import {Cache} from 'aws-amplify';
import {Amplify} from "@aws-amplify/core";

export default {
  components: {
    Search,
    NoItems,
    PageInfo,
    Alert,
    Pagination,
    ResourceTable,
  },
  props: {
    id: String,
    page: Number,
    api: String,
    url: String,
    filter: Function,
    model: Function,
    query: String,
    queryKey: String,
    subscriptionGql: String,
    variables: Object,
    name: String,
    createRoute: String,
    columns: Array,
    disableActions: Boolean,
    forceTable: Boolean,
    dateFilter: Boolean,
    actions: {
      type: Array,
      default: () => actionItems,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    extraParams: Object,
  },
  watch: {
    variables() {
      this.onLoadItems();
    }
  },
  data() {
    return {
      items: [],
      meta: {current_page: this.page, last_page: this.page + 1, infinite: true, per_page: 10,},
      error: null,
      searchQuery: '',
      observer: null,
      nextToken: null,
      nextTokens: [],
      isEmpty: false,
      isLoaded: false,
      isLoading: false,
      submitting: null,
      subscription: null,
    };
  },
  methods: {
    onSearch(event) {
      console.log(event.target.value, "event.target.value")
      this.searchQuery = event.target.value || "";
      this.onLoadItems();
    },
    onPaginate(page) {
      this.onLoadItems(page);
    },
    onView(item) {
      this.$emit('view', item);
    },
    async onAction(action, item) {
      this.submitting = item.id;
      await this.$emit(action, item);
    },
    async reloadTable() {
      await this.onLoadItems(this.meta.current_page);
    },
    async onLoadItems(page = 1) {
      try {
        let data = [];
        if (this.model && !this.query) {
          if (this.filter !== undefined) {
            data = await DataStore.query(this.model, this.filter());
          } else {
            data = await DataStore.query(this.model);
          }
          if (!data.length) {
            this.isLoading = false;
            this.isEmpty = true;
          }
          this.items = data;
          this.isLoading = false;
        }
        if (this.query) {
          this.isLoading = true;
          const variables = this.variables;
          variables.limit = 10;
          if (this.nextTokens[page - 1] !== undefined && this.nextTokens[page - 1] !== null) {
            variables.nextToken = this.nextTokens[page - 1];
          } else {
            delete variables.nextToken;
          }
          if(this.searchQuery){
            variables['filter'] = {
              email: {
                contains: this.searchQuery
              },
            };
          }

          this.meta.current_page = page;
          // if( this.searchQuery ? this.items.length >= 10 : true ){
            await API.graphql(graphqlOperation(this.query, variables)).then(async ({data}) => {
              const realData = data[this.queryKey];
              this.nextTokens[page] = realData.nextToken;
              this.meta.last_page = page;
              this.items = realData.items;
              this.isLoading = false;
              this.isEmpty = false;
              Cache.setItem('nextTokens_' + this.name, this.nextTokens)
              if (realData.nextToken != null ) {
                if (this.items.length === 0 ) {
                  await this.onLoadItems(page + 1);
                } else if(this.items.length >= 10 ){
                  this.meta.last_page = page + 1;
                }
              }
            });
          // }
        }
      } catch (error) {
        this.error = error.data?.message || error.statusText;
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    const nextTokens = Cache.getItem('nextTokens_' + this.name)
    if (nextTokens !== null) {
      this.nextTokens = nextTokens;
    }
    this.onLoadItems();
    this.subscription = Amplify.API.graphql(
        graphqlOperation(this.subscriptionGql)
    ).subscribe({
      next: () => {
        this.reloadTable();
        this.submitting = null;
      },
      error: error => console.warn(error)
    });
  },
  async beforeUnmount() {
    this.subscription.unsubscribe();
  },
};

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }
</script>
