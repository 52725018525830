<template>
  <transition name="fade">
    <div class="flex text-sm font-medium text-secondary">
      <button
        type="button"
        v-if="meta.current_page !== 1"
        class="pagination-button rounded-l"
        @click="$emit('change', meta.current_page - 1)"
      >
        Prev
      </button>
      <button
        type="button"
        class="pagination-button rounded-r"
        v-if="meta.last_page !== meta.current_page"
        @click="$emit('change', meta.current_page + 1)"
      >
        Next
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    meta: Object,
  },
  computed: {
    previousItems() {
      console.log(this.meta, 'pre')

      return [1, 2, 3, 4, 5]
        .filter(item => {
          return this.meta.current_page - item >= 1;
        })
        .map(item => {
          return this.meta.current_page - item;
        });
    },
    nextItems() {
      console.log(this.meta, 'next')
      return [1, 2, 3, 4, 5]
        .filter(item => {
          return item + this.meta.current_page <= this.meta.last_page;
        })
        .map(item => {
          return item + this.meta.current_page;
        });
    },
  },
};
</script>

<style scoped>
.pagination-button {
  @apply py-1 px-4;
}
</style>
